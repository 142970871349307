.fireball-path {
  position: absolute;
  top: -130px;
  left: -130px;
  border-radius: 50%;
  animation: impact 11s ease forwards;
  z-index: 9000;
}

.fireball {
  height: 50px;
  animation: grow-shrink 3s ease forwards;
}

// should fly from behind camera at earth
@keyframes impact {
  0% {
    transform: translateX(-500px) translateY(-500px) rotateX(-57deg);
    opacity: 1; border-radius: 50%; box-shadow: 0px 0px 0px 0px white;
    z-index: 9000;
  }
  30% {
    transform: translateX(800px) translateY(770px) rotateX(-57deg);
    opacity: 1;border-radius: 50%; box-shadow: 0px 0px 0px 0px white;
    z-index: 9000;
  }
  40% {
    transform: translateX(800px) translateY(770px) rotateX(-57deg);
    border-radius: 50%; box-shadow: 0px 0px 30px 50px white; 
    z-index: 9000;
  }
  50% {
    transform: translateX(800px) translateY(770px) rotateX(-57deg);
    border-radius: 50%; opacity: 0.5; box-shadow: 0px 0px 40px 30px white; 
    z-index: 9000;
  }
  65% {
    transform: translateX(800px) translateY(770px) rotateX(-57deg);
    border-radius: 50%; opacity: 0.5; box-shadow: 0px 0px 40px 20px white; 
    z-index: 9000;
  }
  100% {
    transform: translateX(800px) translateY(770px) rotateX(-57deg);
    border-radius: 50%; opacity: 1; box-shadow: 0px 0px 40px 30px #FF2922; 
    z-index: 9000;
  }
}

@keyframes grow-shrink {
  0% {
    height: 300px;
    z-index: 9000;
  }
  100% {
    height: 0px;
    z-index: 9000;
  }
}